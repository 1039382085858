import * as React from 'react';

const ContainersCTA = () => (
  <article className="w-full max-w-5xl mx-auto my-auto">
    <h1 className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
      Packing materials <br />
      and Boxes <br />
      <span className="text-brand-orange">for sale</span>
    </h1>
  </article>
);

export default ContainersCTA;
