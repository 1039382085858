import * as React from 'react';

import { Hero, Layout, SEO } from '../components';
import CTA from '../components/packing-materials/packing-materials-cta';
import FirstSection from '../components/packing-materials/packing-materials-first-section';
import HeroImage from '../components/packing-materials/packing-materials-hero-image';

const PackingMaterialsPage = () => (
  <Layout
    formName="packing-materials-bottom-form"
    formTitle="Moving or storing? Contact us today"
  >
    <SEO
      title="Packing &amp; Moving Boxes For Sale in Port Macquarie"
      description="Moving to a new location? We stock a range of packing materials like cartons,
      bubble wrap, boxes, protectors &amp; covers for sale in Port Macquarie. Call today!"
    />
    <Hero image={<HeroImage />} cta={<CTA />} />
    <FirstSection />
  </Layout>
);

export default PackingMaterialsPage;
